import { Flex } from '@chakra-ui/react'
import Head from 'next/head'
import PropTypes from 'prop-types'

import Footer from '../components/Footer'
import Header from '../components/Header'

const StandardLayout = ({ children, title, description, ...rest }) => {
  return (
    <>
      <Head>
        <title>{`${title} — Sinio`}</title>
        <meta name="description" content={description} />
      </Head>
      <Flex
        width="100%"
        height="100%"
        minHeight="100vh"
        direction="column"
        align="center"
      >
        <Header />
        <Flex
          as="main"
          flex="1"
          width="100%"
          align="center"
          direction="column"
          {...rest}
        >
          {children}
        </Flex>
        <Footer />
      </Flex>
    </>
  )
}

StandardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default StandardLayout
