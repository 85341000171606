import { Box, Center, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'

const SocialBanner = () => {
  return (
    <Box
      width="100%"
      borderRadius="10"
      bgGradient="linear(to-r, #79e5fd, #ac4ebb)"
      marginBottom={14}
    >
      <Stack margin={10} spacing={6}>
        <Text fontSize="3xl" fontWeight={700}>
          Follow us on Social Media
        </Text>
        <Stack direction="row" spacing={6}>
          <Link
            href="https://www.instagram.com/sinio4k"
            target="_blank"
            rel="noreferrer"
          >
            <Center
              width={12}
              height={12}
              backgroundColor="black"
              borderRadius={10}
            >
              <Icon as={FaInstagram} boxSize={6} color="white" />
            </Center>
          </Link>
          <Link
            href="https://www.facebook.com/SinioAdmin"
            target="_blank"
            rel="noreferrer"
          >
            <Center
              width={12}
              height={12}
              backgroundColor="black"
              borderRadius={10}
            >
              <Icon as={FaFacebook} boxSize={6} color="white" />
            </Center>
          </Link>
          <Link
            href="https://www.youtube.com/sinio"
            target="_blank"
            rel="noreferrer"
          >
            <Center
              width={12}
              height={12}
              backgroundColor="black"
              borderRadius={10}
            >
              <Icon as={FaYoutube} boxSize={6} color="white" />
            </Center>
          </Link>
        </Stack>
      </Stack>
    </Box>
  )
}

export default SocialBanner
