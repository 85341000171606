import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Img,
  SimpleGrid,
  Skeleton,
  Stack,
  Text
  // useDisclosure
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useRouter } from 'next/router'
import { FaExclamationCircle, FaInfoCircle, FaVideo } from 'react-icons/fa'
import useSWR from 'swr/immutable'

// import AuthCTAModal from '../components/AuthCTAModal'
import SocialBanner from '../components/SocialBanner'
import StandardLayout from '../layouts/Standard'
import SinioAPIService from '../services/sinioAPI'

const HomePage = () => {
  const router = useRouter()
  const auth = useStoreState((state) => state.auth)
  const { setAuth } = useStoreActions((actions) => actions.auth)

  const country = useStoreState((state) => state.country)
  // const isAuthenticated = !!auth?.user && !!auth?.credentials
  // const {
  //   isOpen: isOpenAuthCTAModal,
  //   onOpen: onOpenAuthCTAModal,
  //   onClose: onCloseAuthCTAModal
  // } = useDisclosure()

  const fetchEvents = async (key, params) => {
    const { credentials, past, upcoming } = params
    const SinioAPI = SinioAPIService({
      credentials,
      setAuth
    })

    const { data } = await SinioAPI.getEvents({
      isPublic: !credentials,
      currency: country.currency,
      ...(past && { past }),
      ...(upcoming && { upcoming })
    })

    return data
  }

  // eslint-disable-next-line no-unused-vars
  const { data: pastEventData, error: pastEventError } = useSWR(
    [
      '/events?past=1',
      {
        credentials: auth?.credentials,
        past: 1
      }
    ],
    fetchEvents
  )
  const { data: upcomingEventData, error: upcomingEventError } = useSWR(
    [
      '/events?upcoming=1',
      {
        credentials: auth?.credentials,
        upcoming: 1
      }
    ],
    fetchEvents
  )

  const upcomingEvents = upcomingEventData ? upcomingEventData.events : []
  const pastEvents = pastEventData ? pastEventData.events : []
  // Use the first item as the featured upcoming event
  const featuredUpcomingEvent =
    !upcomingEvents.length && !pastEvents.length
      ? null
      : upcomingEvents.length
      ? upcomingEvents[upcomingEvents.length - 1]
      : pastEvents[pastEvents.length - 1]

  return (
    <StandardLayout
      title="Home"
      description="Hiburan tanah air kini
    di kaca virtual anda."
    >
      <Flex width="100%" maxWidth="100rem">
        <AspectRatio width="100%" ratio={16 / 9}>
          <Box>
            {!upcomingEventData && !upcomingEventError && (
              <Skeleton
                height="full"
                width="full"
                startColor="gray.800"
                endColor="gray.600"
              />
            )}
            {upcomingEventError && (
              <Stack
                background="black"
                width="full"
                height="full"
                align="center"
                justify="center"
              >
                <Icon as={FaExclamationCircle} boxSize={10} color="red.500" />
                <Text as="div" fontSize="2xl" fontWeight={700} color="red.500">
                  Unable to load event.
                </Text>
              </Stack>
            )}
            {upcomingEventData &&
              !upcomingEventError &&
              !featuredUpcomingEvent && (
                <Stack
                  background="black"
                  width="full"
                  height="full"
                  align="center"
                  justify="center"
                >
                  <Icon as={FaInfoCircle} boxSize={10} color="blue.500" />
                  <Text
                    as="div"
                    fontSize="2xl"
                    fontWeight={700}
                    color="blue.500"
                  >
                    No upcoming event.
                  </Text>
                </Stack>
              )}
            {featuredUpcomingEvent && (
              <>
                {featuredUpcomingEvent.promo_pix ||
                featuredUpcomingEvent.preview ? (
                  <>
                    {featuredUpcomingEvent.preview ? (
                      <video
                        width="100%"
                        src={featuredUpcomingEvent.preview}
                        style={{ width: '100%', height: '100%' }}
                        autoPlay
                        loop
                        muted
                        playsInline
                      />
                    ) : (
                      <>
                        <Img
                          src={featuredUpcomingEvent.promo_pix}
                          objectFit="cover"
                          width="full"
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Center width="full" height="full" background="black">
                    <Icon as={FaVideo} boxSize="20%" color="#1d1d1d" />
                  </Center>
                )}
                <Flex
                  width="full"
                  height="full"
                  position="absolute"
                  bottom="0"
                  left="0"
                  align="flex-end"
                >
                  <Stack
                    width="full"
                    borderRadius={10}
                    margin={{ base: 2, md: 4, lg: 6 }}
                    padding={{ base: 2, md: 4, lg: 6 }}
                    backgroundColor="blackAlpha.500"
                    direction="row"
                    align="center"
                  >
                    <Stack width="100%" align="flex-start">
                      <Heading
                        as="h3"
                        size={{ base: 'md', md: 'lg', lg: 'lg' }}
                        noOfLines={1}
                      >
                        {featuredUpcomingEvent.title}
                      </Heading>
                      <Text
                        as="div"
                        noOfLines={1}
                        fontSize={{ base: 'md', md: 'lg', lg: 'xl' }}
                        wordBreak="break-word"
                        textOverflow="ellipsis"
                      >
                        {featuredUpcomingEvent.description ||
                          'No description available for this event.'}
                      </Text>
                    </Stack>
                    <Button
                      size={{ base: 'lg' }}
                      colorScheme="whatsapp"
                      padding={7}
                      onClick={() =>
                        featuredUpcomingEvent.access_granted
                          ? router.push(`/events/${featuredUpcomingEvent.id}`)
                          : router.push(
                              `/events/${featuredUpcomingEvent.id}/purchase`
                            )
                      }
                    >
                      {featuredUpcomingEvent.access_granted ? 'Watch Now' : ''}
                      {!featuredUpcomingEvent.access_granted &&
                      featuredUpcomingEvent.status === 'ended' &&
                      featuredUpcomingEvent.price_ondemand
                        ? featuredUpcomingEvent.price_ondemand.toLocaleString(
                            'en-US',
                            {
                              style: 'currency',
                              currency: country.currency
                            }
                          )
                        : ''}
                      {!featuredUpcomingEvent.access_granted &&
                      featuredUpcomingEvent.status !== 'ended' &&
                      featuredUpcomingEvent.price_liveticket
                        ? featuredUpcomingEvent.price_liveticket.toLocaleString(
                            'en-US',
                            {
                              style: 'currency',
                              currency: country.currency
                            }
                          )
                        : ''}
                    </Button>
                  </Stack>
                </Flex>
              </>
            )}
          </Box>
        </AspectRatio>
      </Flex>
      <Stack
        width="100%"
        maxWidth="8xl"
        paddingX={{ base: 3, md: 4, lg: 5 }}
        paddingY={{ base: 6, md: 8, lg: 10, xl: 12 }}
        spacing={{ base: 6, md: 8, lg: 10, xl: 12 }}
        direction="column"
      >
        <Stack width="100%" spacing={6}>
          <Heading
            as="h2"
            size={{ base: 'lg', md: 'xl', lg: '2xl' }}
            noOfLines={1}
          >
            Popular Releases
          </Heading>
          <SimpleGrid
            padding={{ base: 3, md: 4, lg: 5 }}
            columns={{ base: 1, sm: 2, md: 3, '2xl': 4 }}
            spacing={{ base: 4, md: 6, lg: 8, xl: 10 }}
            width="100%"
          >
            {pastEvents.map((event) => (
              <Box
                key={event.id}
                cursor="pointer"
                onClick={() => router.push(`/events/${event.id}`)}
              >
                <AspectRatio width="100%" ratio={1}>
                  {event.promo_pix ? (
                    <Img
                      borderRadius="10"
                      backgroundColor="gray.800"
                      src={event.promo_pix}
                      objectFit="cover"
                      width="full"
                      height="full"
                    />
                  ) : (
                    <Center width="full" height="full" background="gray.400">
                      <Icon as={FaVideo} boxSize="20%" color="gray.500" />
                    </Center>
                  )}
                </AspectRatio>
                <Text size="sm">
                  {dayjs(event.date_time).format('D MMM YYYY')}
                </Text>
                <Heading
                  as="h4"
                  size={{ base: 'md', lg: 'lg' }}
                  fontWeight={700}
                >
                  {event.title}
                </Heading>
                <Text size="sm" color="gray.300">
                  {event.status === 'ended' && !event.price_ondemand
                    ? 'FREE'
                    : ''}
                  {event.status !== 'ended' && !event.price_liveticket
                    ? 'FREE'
                    : ''}
                  {event.status === 'ended' && event.price_ondemand
                    ? event.price_ondemand.toLocaleString('en-US', {
                        style: 'currency',
                        currency: country.currency
                      })
                    : ''}
                  {event.status !== 'ended' && event.price_liveticket
                    ? event.price_liveticket.toLocaleString('en-US', {
                        style: 'currency',
                        currency: country.currency
                      })
                    : ''}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Stack>
        {/* <Box
          width="100%"
          marginTop={{ base: 4, md: 8, lg: 12 }}
          marginBottom={8}
        >
          <Img
            borderRadius="10"
            src="https://via.placeholder.com/970x250/eee?text=Ad (970x250)"
            objectFit="cover"
            width="full"
          />
        </Box> */}
        <SocialBanner />
      </Stack>
    </StandardLayout>
  )
}

export default HomePage
